import * as React from 'react'
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography as Text,
  ListItemSecondaryAction,
  IconButton,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import FavoriteIcon from '@material-ui/icons/Favorite'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'

import {
  faArchway,
  faCity,
  faBuilding,
  faSchool,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  handleSearchBarToggle,
  setLatLang,
  updateBottomSheet,
} from '../../../sections/Map/redux/myMapsSlice'
import {
  addBuildingToFavorite,
  handleViewBuildingInfoSheet,
  removeBuildingFromFavorite,
  setSelectedBuilding,
  setSelectedCampusBuildings,
  setSelectedFavoritedBuildings,
} from '../../../sections/Buildings/redux/myBuildingsSlice'
import { buildingListComponentStyles } from '../BuildingListComponent/buildingListComponentStyles'
import { sortListAlphabetically } from '../../../utils/helpers'
import { logMapEvent } from '../../../utils/events'
import {
  NEW_BRUNSWICK_COLOR,
  BUILDING,
  BUILDINGFAVORITE,
  BUILDINGUNFAVORITE,
  CAMDEN_COLOR,
  NEWARK_COLOR,
  OTHER_COLOR,
  NEW_BRUNSWICK,
  PISCATAWAY,
  NEWARK,
  NewBrunswick,
  Camden,
  CAMDEN,
} from '../../../utils/constants'
import { storeFavoriteBuldingListToFirebase } from '../../../sections/Buildings/firebase/firebase'

const BuildingListItem = ({ building, name, city, bid, isFavorited }) => {
  const [favorited, setFavorited] = React.useState(isFavorited)

  const classes = buildingListComponentStyles()
  const dispatch = useDispatch()
  let {
    // eslint-disable-next-line prefer-const
    favoritedBuildings,
    // eslint-disable-next-line prefer-const
    selectedCampusBuildings,
  } = useSelector((s) => s.buildings)

  function filterBuildingsByFavorite(buildings, favoriteBids) {
    return buildings.filter((buildingS) => !favoriteBids.has(buildingS.bid))
  }
  const favoriteBids = new Set(
    favoritedBuildings.map((favorite) => favorite?.bid),
  )

  const handleClick = () => {
    dispatch(handleSearchBarToggle(false))
    dispatch(setSelectedBuilding(building))
    dispatch(setLatLang({ lat: building.lat, lang: building.lng, zoom: 18 }))
    dispatch(handleViewBuildingInfoSheet(bid))
    dispatch(updateBottomSheet(BUILDING))
  }

  const addToFavorite = (e) => {
    e.stopPropagation()
    let newFavoriteList = []
    if (favorited) {
      // If already favorited, remove
      newFavoriteList = favoritedBuildings.filter((b) => b.bid !== bid)
      addToSelectedBuildingListByCity(city)
      dispatch(removeBuildingFromFavorite(newFavoriteList))
      setFavorited(false)
    } else {
      // To do add building to list
      newFavoriteList = [...favoritedBuildings]
      newFavoriteList.push(building)
      removeFromSelectedBuildingListByCity(city)
      dispatch(addBuildingToFavorite(newFavoriteList))
      setFavorited(true)
    }
    dispatch(
      setSelectedFavoritedBuildings(sortListAlphabetically(newFavoriteList)),
    )
    storeFavoriteBuldingListToFirebase(newFavoriteList.map((b) => b.bid))
    logMapEvent(
      dispatch,
      favorited ? BUILDINGUNFAVORITE : BUILDINGFAVORITE,
      building.name,
    )
  }

  const getIconColor = (cityName) => {
    switch (cityName) {
      case NEW_BRUNSWICK:
        return { icon: faArchway, color: NEW_BRUNSWICK_COLOR }
      case PISCATAWAY:
        return { icon: faArchway, color: NEW_BRUNSWICK_COLOR }
      case NEWARK:
        return { icon: faCity, color: NEWARK_COLOR }
      case CAMDEN:
        return { icon: faBuilding, color: CAMDEN_COLOR }
      default:
        return { icon: faSchool, color: OTHER_COLOR }
    }
  }
  const { icon, color } = getIconColor(city)

  const addToSelectedBuildingListByCity = (c) => {
    if (c === NEW_BRUNSWICK || c === PISCATAWAY) {
      dispatch(
        setSelectedCampusBuildings({
          ...selectedCampusBuildings,
          'New Brunswick': sortListAlphabetically(
            filterBuildingsByFavorite(
              selectedCampusBuildings[NewBrunswick],
              favoriteBids,
            ).concat(building),
          ),
        }),
      )
    } else if (c === NEWARK) {
      dispatch(
        setSelectedCampusBuildings({
          ...selectedCampusBuildings,
          Newark: sortListAlphabetically(
            filterBuildingsByFavorite(
              selectedCampusBuildings.Newark,
              favoriteBids,
            ).concat(building),
          ),
        }),
      )
    } else if (c === Camden) {
      dispatch(
        setSelectedCampusBuildings({
          ...selectedCampusBuildings,
          Camden: sortListAlphabetically(
            filterBuildingsByFavorite(
              selectedCampusBuildings.Camden,
              favoriteBids,
            ).concat(building),
          ),
        }),
      )
    } else {
      dispatch(
        setSelectedCampusBuildings({
          ...selectedCampusBuildings,
          Other: sortListAlphabetically(
            filterBuildingsByFavorite(
              selectedCampusBuildings.Other,
              favoriteBids,
            ).concat(building),
          ),
        }),
      )
    }
  }

  const removeFromSelectedBuildingListByCity = (c) => {
    if (c === NEW_BRUNSWICK || c === PISCATAWAY) {
      dispatch(
        setSelectedCampusBuildings({
          ...selectedCampusBuildings,
          'New Brunswick': sortListAlphabetically(
            filterBuildingsByFavorite(
              selectedCampusBuildings[NewBrunswick],
              favoriteBids,
            ).filter((b) => b.bid !== building.bid),
          ),
        }),
      )
    } else if (c === NEWARK) {
      dispatch(
        setSelectedCampusBuildings({
          ...selectedCampusBuildings,
          Newark: sortListAlphabetically(
            filterBuildingsByFavorite(
              selectedCampusBuildings.Newark,
              favoriteBids,
            ).filter((b) => b.bid !== building.bid),
          ),
        }),
      )
    } else if (c === CAMDEN) {
      dispatch(
        setSelectedCampusBuildings({
          ...selectedCampusBuildings,
          Camden: sortListAlphabetically(
            filterBuildingsByFavorite(
              selectedCampusBuildings.Camden,
              favoriteBids,
            ).filter((b) => b.bid !== building.bid),
          ),
        }),
      )
    } else {
      dispatch(
        setSelectedCampusBuildings({
          ...selectedCampusBuildings,
          Other: sortListAlphabetically(
            filterBuildingsByFavorite(
              selectedCampusBuildings.Other,
              favoriteBids,
            ).filter((b) => b.bid !== building.bid),
          ),
        }),
      )
    }
  }

  return (
    <ListItem
      onClick={() => handleClick()}
      key={`${name}-${city}-${bid}`}
      className={classes.listItemView}
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '8px 16px',
        cursor: 'pointer',
      }}
    >
      <ListItemIcon style={{ minWidth: '40%', color }}>
        <FontAwesomeIcon
          icon={icon}
          style={{ paddingTop: '4px', fontSize: '1.3rem' }}
        />
        <ListItemText
          disableTypography
          primary={
            <Text type="body2" className={classes.buildingListText}>
              {name}
            </Text>
          }
        />
      </ListItemIcon>
      <ListItemSecondaryAction>
        <IconButton
          edge="end"
          aria-label="add/remove favorited building"
          onClick={addToFavorite}
        >
          {favorited ? (
            <FavoriteIcon className={classes.favoriteIconSelected} />
          ) : (
            <FavoriteBorderIcon className={classes.iconButtonBorder} />
          )}
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

export default BuildingListItem
