import * as React from 'react'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { useDispatch, useSelector } from 'react-redux'
import Widget from '../Widget'
import {
  fetchDidYouKnow,
  selectDYKData,
  selectDYKStatus,
} from './didYouKnowSlice'
import didYouKnowWidgetStyles from './didYouKnowStyles'
import Loading from '../helpers/Loading'
import { ErrorMessage } from '../../DashboardComponents/ErrorMessages/ErrorMsg'

// Q: gimme a list of ALL the widgets in the codebase have this line <Grid item>Error</Grid>
// A: DidYouKnow, News, Weather, Events, Announcements, QuickLinks, SocialMedia, EmergencyContacts

/*
 * This is the Component UI, it is responsible for calling the redux action for getting data fetchDidYouKnow. Every component that gets widgetData will have a redux function for calling data in this case the function fetchDidYouKnow. The result of the widgetData call will be sent to a state function like renderDYKState.
 */
// eslint-disable-next-line no-unused-vars
function DidYouKnow({
  className,
  title,
  showNote,
  noteMessage,
  notePosition,
  noteBackground,
  noteIcon,
  noteUrl,
  noteFontColor,
}) {
  const dispatch = useDispatch()
  const widgetData = useSelector(selectDYKData)
  const widgetCallState = useSelector(selectDYKStatus)
  React.useEffect(() => {
    dispatch(fetchDidYouKnow())
  }, [])
  return (
    <Widget className={className}>
      <Widget.Header title={title} category="default" />
      <Widget.Content
        showNote={showNote}
        noteMessage={noteMessage}
        notePosition={notePosition}
        noteBackground={noteBackground}
        noteIcon={noteIcon}
        noteUrl={noteUrl}
        noteFontColor={noteFontColor}
      >
        {renderDYKState(widgetCallState, widgetData)}
      </Widget.Content>
    </Widget>
  )
}

// Since the fetchDidYouKnow call is async we use a switch statement to handle returning a UI for 3 different possible states. 'fulfilled' when the data is returned, 'pending' when the call is still pending and 'error' if there is an error when obtaining the data.
function renderDYKState(widgetCallState, widgetData) {
  // TODO: Handle Proper Error States
  switch (widgetCallState) {
    case 'fulfilled':
      return <Loaded widgetData={widgetData} />
    case 'pending':
      return <Loading />
    case 'error':
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <ErrorMessage />
        </Grid>
      )
    default:
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item></Grid>
        </Grid>
      )
  }
}

function Loaded(props) {
  const classes = didYouKnowWidgetStyles()
  const categoriesList = props.widgetData.categories?.map((category) => (
    <li key={category}>{category}</li>
  ))
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      className={classes.gridContainer}
    >
      <Grid item>
        <img
          style={{ width: 250 }}
          src={props.widgetData.image}
          alt="Did You Know Rutgers Logo"
        />
      </Grid>
      <Grid item>
        <Typography variant="body1"> {props.widgetData.content} </Typography>
      </Grid>
      <Grid item>
        <ul className={classes.categoriesList}>
          <Typography variant="subtitle1">{categoriesList}</Typography>
        </ul>
      </Grid>
    </Grid>
  )
}

export default DidYouKnow
