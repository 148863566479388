export const ROUTE = 'ROUTE'
export const ROUTES = 'ROUTES'
export const VEHICLE = 'VEHICLE'
export const SEARCH = 'SEARCH'
export const BUILDING = 'BUILDING'
export const RESTAURANT = 'RESTAURANT'
export const NEARESTSTOP = 'NEARESTSTOP'
export const FIREBASEMARKER = 'FIREBASEMARKER'
export const CAMDENENGAGEBUILDING = 'CAMDENENGAGEBUILDING'
export const STOPMARKER = 'stopMarker'
export const ROUTEMARKER = 'routeMarker'
export const VEHICLEMARKER = 'vehicleMarker'
export const BUSFAVORITE = 'busFavorite'
export const BUSUNFAVORITE = 'busUnFavorite'
export const STOPSFAVORITE = 'stopsFavorite'
export const STOPSUNFAVORITE = 'stopsUnFavorite'
export const BUILDINGFAVORITE = 'buildingFavorite'
export const BUILDINGUNFAVORITE = 'buildingUnFavorite'
export const NEARESTSTOPSBUTTON = 'nearestStopsButton'
export const DIRECTIONSMAP = 'directionsMap'
export const PARKINGMAP = 'parkingMap'

// ====
// Campus names which are used throughout the app
// Uppercase is used for key's in API response
// Lowercase is for our code base
export const NewBrunswick = 'New Brunswick'
export const NEW_BRUNSWICK = 'NEW BRUNSWICK'
export const Piscataway = 'Piscataway'
export const PISCATAWAY = 'PISCATAWAY'
export const Newark = 'Newark'
export const NEWARK = 'NEWARK'
export const Camden = 'Camden'
export const CAMDEN = 'CAMDEN'
export const Other = 'Other'
export const OTHER = 'OTHER'
// ====

export const CAMPUSLIST = [NewBrunswick, Newark, Camden]
export const BOTTOMSHEET_SEARCH_TAB_LABELS_DESKTOP = [
  'Routes',
  'Stops',
  'Buildings',
]
export const BOTTOMSHEET_SEARCH_TAB_LABELS_MOBILE = ['Routes', 'Stops']

export const CAMPUS_MAP = {
  'NEW BRUNSWICK': NewBrunswick,
  NEWARK: Newark,
  CAMDEN: Camden,
}

export const NEW_BRUNSWICK_COLOR = '#cc0033'
export const NEWARK_COLOR = '#9a001b'
export const CAMDEN_COLOR = '#e08990'
export const OTHER_COLOR = '#9b9b9b'
