import React from 'react'
import Switch from '@material-ui/core/Switch'
import { Typography, Grid, Chip, ButtonBase } from '@material-ui/core'

import settingsStyles from '../settingsStyles'

function PageLink({ title, description, action, state }) {
  const classes = settingsStyles()

  return (
    <ButtonBase className={classes.fullWidth} tabIndex={0} onClick={action}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={classes.linkGrid}
      >
        <Grid item xs container direction="column" alignItems="flex-start">
          <Typography variant="h6">{title}</Typography>
          <Typography variant="caption">{description}</Typography>
        </Grid>
        {state === true ? (
          <Chip
            className={classes.pageToggleChip}
            color="primary"
            size="medium"
            label="Action Successful"
          />
        ) : null}
        <Grid item>
          <Switch checked={state} aria-label={description} tabIndex={-1} />
        </Grid>
      </Grid>
    </ButtonBase>
  )
}

export default PageLink
